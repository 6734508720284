<template>
  <BasePage disableFooter disablePreFooter>
    <template v-slot:content>
      <section>
        <v-container >
          <v-fade-transition mode="out-in">
            <!-- request to be sent -->
            <v-row v-if="state === 'initial'">
              <v-col cols="12" md="10" offset-md="1" lg="9" offset-lg="2">
                <Cta reverse colorSchema="none" action="internal" label="Back to login" :to="{name: 'Login'}" />
                <h1 class="-sci-gradient mt4">Recover your password</h1>
                <p class="mb-4">Enter your email, we will send a link to reset</p>
              </v-col>
              <v-col cols="12" sm="6" md="5" offset-md="1" lg="4" offset-lg="2">
                <Form ref="requestForm" action="javascript:void(0);" >
                  <InputText
                    required
                    label="Email"
                    name="email"
                    placeholder="Your email"
                    v-model="email"
                    :rules="$auth.emailRules"
                  />
                  <Button class="mb-4" @click="sendRequest" type="submit" :disabled="$auth.working">
                    Send
                  </Button>
                  <p class="mb-0 mt-4">Already received an email?</p>
                  <Cta
                    action="button"
                    @click="state = 'answerForgotPasswordCode'"
                    colorSchema="none"
                    class="mb-4"
                    label="Enter the code manually"
                  />
                </Form>
              </v-col>
            </v-row>
            <v-row v-else-if="state === 'requestSent'">
              <v-col cols="12" md="10" offset-md="1" lg="9" offset-lg="2">
                <Cta reverse action="button" colorSchema="none" label="Back" @click="back"/>
                <h1 class="-sci-gradient mb-4">Request sent</h1>
              </v-col>
              <v-col cols="12" sm="6" md="5" offset-md="1" lg="4" offset-lg="2">
                <p>If the email is valid you will receive a link to reset your password</p>
                <p class="mb-0">You didn't receive the email?</p>
                <Cta
                  action="button"
                  @click="sendRequest"
                  colorSchema="none"
                  class="mb-4"
                  label="Send it again"
                  :disabled="$auth.working"
                />
                <p class="mb-0">Can't click the link in the email?</p>
                <Cta
                  action="button"
                  @click="state = 'answerForgotPasswordCode'"
                  colorSchema="none"
                  class="mb-4"
                  label="Enter the code manually"
                  :disabled="$auth.working"
                />
              </v-col>
            </v-row>

            <Form
              v-else-if="state === 'answerForgotPasswordCode'"
              ref="changePasswordForm"
              action="javascript:void(0);"
            >
              <v-row>
                <template v-if="!precompiledEmail && !precompiledCode">
                  <v-col cols="12" md="10" offset-md="1" lg="9" offset-lg="2">
                    <h2 class="-sci-gradient mb-4">Enter your email and the code you received</h2>
                  </v-col>
                  <v-col cols="12" sm="6" md="5" offset-md="1" lg="4" offset-lg="2">
                    <InputText
                      required
                      label="Email"
                      name="email"
                      placeholder="Your email"
                      v-model="email"
                      :rules="$auth.emailRules"
                      :disabled="precompiledEmail"
                      autocomplete="username"
                    />
                    <InputText
                      required
                      label="Reset Code"
                      name="reset-code"
                      placeholder="Code"
                      v-model="code"
                      :rules="$auth.codeRules"
                      :disabled="precompiledCode"
                    />
                  </v-col>
                </template>
                <v-col cols="12" md="10" offset-md="1" lg="9" offset-lg="2">
                  <h2 class="-sci-gradient mb-4">Reset your password</h2>
                </v-col>

                <v-col cols="12" sm="6" md="5" offset-md="1" lg="4" offset-lg="2">
                  <p>The password must contain a minimum of 8 characters,<br>at least 1 number, 1 uppercase, 1 lowercase.</p>
                  <InputText
                    required
                    type="password"
                    label="New Password"
                    name="password"
                    placeholder="New password"
                    v-model="newPassword"
                    :rules="$auth.passwordRules"
                  />
                  <InputText
                    required
                    type="password"
                    label="Confirm the New Password"
                    name="password"
                    placeholder="New password"
                    v-model="confirmNewPassword"
                    :rules="$auth.passwordRules.concat(passwordConfirmRule)"
                  />
                  <Button class="mb-4" @click="answerForgotPasswordCode" type="submit" :disabled="$auth.working">
                    Confirm
                  </Button>
                </v-col>
              </v-row>
            </Form>
            <v-row v-else-if="state === 'passwordChanged'">
              <v-col cols="12" md="10" offset-md="1" lg="9" offset-lg="2">
                <Cta reverse action="button" colorSchema="none" label="Back" @click="back"/>
                <h1 class="-sci-gradient mb-4">Password changed successfully</h1>
                <p>You can now use the new password to login.</p>
                <Cta colorSchema="none" action="internal" label="Go to login" :to="{name: 'Login'}" />
              </v-col>
            </v-row>
          </v-fade-transition>
        </v-container>
      </section>
    </template>
  </BasePage>
</template>

<script>
import BasePage from '@/organisms/BasePage'
import Cta from '@/molecules/Cta'
import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import InputText from '@/atoms/InputText'
import trim from 'lodash/trim'

export default {
  name: 'ForgotPasswordPage',

  props: {
    precompiledEmail: {
      type: String,
      required: false,
      default: null
    },
    precompiledCode: {
      type: String,
      required: false,
      default: null
    }
  },

  components: {
    BasePage,
    Cta,
    Form,
    InputText,
    Button
  },

  data () {
    return {
      state: 'initial',
      requestFormValid: true,
      insertNewPasswordFormValid: true,
      email: this.precompiledEmail,
      code: this.precompiledCode,
      newPassword: null,
      confirmNewPassword: null,
      passwordConfirmRule: v => v === this.newPassword || 'Passwords must match'
    }
  },
  mounted () {
    // if (this.$auth.user) {
    //   this.$router.push(this.$route.query.to || { name: 'Home' })
    // }

    if (this.precompiledEmail && this.precompiledCode) {
      this.state = 'answerForgotPasswordCode'
    }
  },
  methods: {
    back () {
      this.reset()
      this.state = 'initial'
      if (this.precompiledEmail || this.precompiledCode) {
        this.$router.replace({ params: { precompiledEmail: null, precompiledCode: null } })
      }
    },
    reset () {
      this.email = null
      this.confirmNewPassword = null
      this.newPassword = null
      this.code = null
    },
    async sendRequest () {
      if (this.$refs.requestForm.validate()) {
        try {
          await this.$auth.sendForgotPasswordRequest(trim(this.email).toLowerCase())
          this.state = 'requestSent'
        } catch (e) {
          console.error(e)
        }
      }
    },

    async answerForgotPasswordCode () {
      if (this.$refs.changePasswordForm.validate()) {
        try {
          await this.$auth.answerForgotPasswordCode(this.email, this.code, this.newPassword)
          this.state = 'passwordChanged'
        } catch (e) {
          console.error(e)
        }
      }
    }
  },

  watch: {
    // '$auth.user' (value) {
    //   if (value) {
    //     this.$router.push(this.$route.query.to || { name: 'Home' })
    //   }
    // }
  },

  metaInfo () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
section {
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}
</style>
